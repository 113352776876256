/* DESIGN FOR DIALOG BOX... */

.dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    overflow: auto;
    max-height: 100%;
}

.card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    overflow: hidden;
    width: 100%;
    max-height: 100%;
    height: 350px;
    margin-bottom: 10px;    
}

.card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background-color: transparent;
    color: #ffffff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.photo-holder {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 85px;
    height: 85px;
    background-color: #ccc;
    border: 2px solid black;
}

.title {
    position: absolute;
    top: 155px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(0, 0, 255);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.sub-title{
    position: absolute;
    top: 165px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(39, 116, 189);
    white-space: nowrap;
}

.data-container {
    position: absolute;
    top: 180px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    width: 90%;
}

.label-column {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.values-column {
    font-weight: 500;
    color: black;
    padding-left: 4px;
    flex: 2;  
    width: 23ch; 
    word-wrap: break-word;  
}

@media print {
    @page {
        size: 56mm 88mm; 
        margin: 0;
    }

    .dialog-container, .card-container, .card-footer, .photo-holder, .title, .sub-title, .data-container {
        margin: 0;
        padding: 0;
    }

    .card-container {
        height: 88mm; 
    }
}