.service{
  min-width: 100%;
}
  /* Hero section */
.heroSection {
  position: relative;
  height: 100vh;
}

/* Hero Image */
.heroImage {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  z-index: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../Images/Hero.jpg');
  background-size: cover;
}

/* Hero Text */
.heroText {
  position: absolute;
  left: 0;
  top: 75px; /* Starts below the navbar */
  margin: 2.5em 0 0 8.6875em; /* Adjusted to margin */
  z-index: 1;
  color: #FFF;
}

.title {
  font-family: Poppins;
  max-width: 563px;
  font-size: 49px;
  font-weight: 600;
  line-height: 113.4%;
  letter-spacing: -0.98px;
  margin-bottom: 0.5em;
}

.subtitle {
  font-family: Poppins;
  font-size: 75px;
  font-weight: 600;
  line-height: 113.4%;
  letter-spacing: -1.5px;
  margin-bottom: 1em;
}

.description {
  font-family: Roboto;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1em;
}

/* Contact Button */
.contactButton {
  color: #000;
  line-height: inherit;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 3em;
  padding: 1rem 2.875rem;
  text-decoration: none;
  display: inline-flex;
  margin-top: 1.5em;
}

.contactButton:hover {
  color: #FFF;
  background-color: #000;
  flex-shrink: 0;
  border-radius: 60px;
  border: 1px solid #FFF;
  background: rgba(255, 0, 0, 0.00);
}

/* Responsive Design */
@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
    margin-bottom: 0.6em;
  }
  .subtitle {
    font-size: 4.5rem;
    margin-bottom: 0.4em;
  }
  .description {
    font-size: 22px;
    max-width: 315px;
    margin-bottom: 0%;
  }
  .contactButton {
    padding: 1rem 2.5rem;
  }
  .heroText {
    margin: 30px 0 0 60px; /* Adjusted margin */
  }
  .heroText {
    margin: 2.5em 0 0 10px; /* Adjusted to margin */
  }
}

/* ***************************************Services Section******************************** */
/* Root styles for the services section */
.services { 
padding-top:7.9375em;
width:100%;
overflow:hidden;
box-sizing: border-box;
max-width: 1440px;
margin: auto;

}

/* Heading 1 styles */
.services h1 {
color: #2B353E;
font-family: Poppins, sans-serif;
font-size: 4.6875rem;
font-weight: 500;
line-height: 1.28;
text-align: center;
margin: auto;
}

/* Heading 2 styles */
.services h2 {
color: #2B353E;
font-family: Inter, sans-serif;
font-size: 3.625rem;
font-weight: 700;
line-height: 1.539;
margin: 0;
}

/* Paragraph styles */
.services p {
color: rgba(43, 53, 62, 0.70);
font-family: NanumGothic, sans-serif;
font-size: 1.5625rem;
font-weight: 400;
line-height: 1.45;
margin: 0;
}

/* Container styles */
.container{
display: flex;
justify-content: space-between;
padding: 5% 0;
align-items: flex-start;

}

.containerOdd {
  display: flex;
  justify-content: space-between;
  padding: 5% 0;
  align-items: flex-start;
  }



/* Odd container styles */
.container {
flex-direction: row-reverse;
text-align: flex-end;
}

/* Text container styles */
.left{
flex: 1;  /* Takes up half the space */
padding: 20px;  /* Padding around the text */
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}
.right {
  flex: 1;  /* Takes up half the space */
  padding: 20px;  /* Padding around the text */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  }

/* Image container styles */
.image {
  background-size: contain;
  width: 595px;
  height:362px;
flex: 1;  /* Takes up half the space */
padding: 20px;  /* Padding around the image */
max-width: 100%;  /*Doesn't exceed its container */
object-fit: cover;  /* Covers the available space */
}

/* Mobile specific styles */
@media screen and (max-width: 600px) {
.services h2{
  text-align: center;
  font-size: 2.5rem;
  margin: auto;
}
.services p{
  text-align: center;
  font-size: 1.3rem;
  margin: auto;
}
/* Stacks layout vertically */
.container, .containerOdd {
  flex-direction: column;
  align-items: center;
}

/* Allows text and images to take full width */
.left, .right, .image {
  flex: 1;
  max-width: 100%;
}
}

/* ***************************************Rating Section******************************** */
* {
  box-sizing: border-box;
}
.bigScreen{
  display: flex;
  max-width: 1440px;
  margin: auto;
}
.ratingSection {
  margin-top: 6em;
  width: 100%;
  height: auto;
  background: #E8F1FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5% 5%;
}
.imageSection,
.textSection {
  flex: 1;
}

.imageSection img {
  max-width: 100%;
  height: auto;
}

.textSection {
  padding: 18px 0 0 5%;
  overflow: hidden;
}

.textSection h1 {
  color: #2B353E;
  font-family: Poppins;
  font-size: 45px;
  font-weight: 500;
  line-height: 128.4%;
}

.textSection h2 {
  color: rgba(43, 53, 62, 0.70);
  font-family: Roboto;
  font-size: 21px;
  font-weight: 400;
  line-height: 161.7%;
}

.buttons {
  margin-top: 20px;
}

.contactButtonRating{
  color: #FFF;
  line-height: inherit;
  cursor: pointer;
  background-color: #1E71FF;
  border: none;
  border-radius: 3em;
  padding: 1rem 2.875rem;
  text-decoration: none;
  display: inline-flex;
  border: 1px solid transparent;
  margin-top: 1.5em;
}
.contactButtonRating:hover {
  background-color: #1e87ff;
  ;
}
.star{
  top:0;
}

.loginButtonRating {
  color:#1E71FF ;
  line-height: inherit;
  cursor: pointer;
  background-color: #FFF;
  border: none;
  border-radius: 3em;
  padding: 1rem 2.875rem;
  text-decoration: none;
  display: inline-flex;
  border: 1px solid #1E71FF;
  margin-top: 1.5em;
  margin-left: 12px;
}
.loginButtonRating:hover {
  color: #FFF;
  line-height: inherit;
  cursor: pointer;
  background-color: #1E71FF;
  border: none;
  border-radius: 3em;
  padding: 1rem 2.875rem;
  text-decoration: none;
  display: inline-flex;
  border: 1px solid transparent;
  margin-top: 1.5em;
}


.textSection hr {
  width: 167px;
  height: 1px;
  background: rgba(43, 53, 62, 0.15);
  margin: 90px 0 47px;
}

.starRating {
    display: flex;
    align-items: flex-start;    
}

.starRating img {
  width: 131.494px;
  height: 22.8px;
}

.starRating p {
  color: rgba(43, 53, 62, 0.70);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 161.7%;
  max-width: 90%; /* Limit the width */
  margin-left: 25px;
  white-space: nowrap;  /* Prevents text from breaking into the next line */
  overflow: hidden;  /* Hides overflow text */
  /* text-overflow: ellipsis;   Adds '...' for overflow text */
}

/* Mobile Responsiveness */
@media (max-width: 768px){
  .bigScreen{
    max-width: auto;
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 860px) {
  .image{
    background-size: auto;
    width:auto;
    height: auto;
  }
}
@media (max-width: 768px) {
  .ratingSection {
    margin-top: 6em;
    flex-direction: column-reverse;
    align-items: center;
  }

  .imageSection img {
    margin-top: 5%;
    width: 100%;
    height: auto;
  }

  .textSection {
    margin-right: 0;
    text-align: center;
  }
  .textSection h1{
    font-size: 2rem;
  }
  .textSection h2{
    font-size: 1rem;
  }
  .starRating{
    margin: auto;
    justify-content: center;
    flex-direction: row;
  }
  .starRating p {
    white-space: normal;  /* Allows text to break into the next line for small screens */
  }
  .textSection hr {
    margin: 4em auto 2em;
  }
  .contactButtonRating{
    padding:1em 2em;
  }
  .loginButtonRating{
    padding:1em 2em;
  }
  .loginButtonRating:hover{
    padding:1em 2em;
  }
  .starRating p {
    font-size: 0.8em;
  }

  
  /* Modify text size or other styles for mobile here */
}



