/* COMMON DATE PICKER... */

.datepicker {
  display: flex;
  width: 90%;
  height: 3em;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  padding-left: 10px;
}

.datepicker:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

.datepicker-component {
  width: 100%;
  height: 2.8em;
  display: block;
  font-size: 15px;
  text-align: left;
  outline: none;
}

.datepicker-inner-component {
  width: 100%;
  height: 2.8em;
  outline: none;
  font-size: 15px;
  text-align: left;
  border-style: none;
  padding-left: 10px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

/* DATE PICKER FOR VIEW SCREENS... */

.view-datepicker {
  display: flex;
  width: 20em;
  height: 3em;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  padding-left: 10px;
}

.view-datepicker:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

.view-datepicker-component {
  width: 100%;
  height: 2.8em;
  display: block;
  font-size: 15px;
  text-align: left;
  outline: none;
}

.view-datepicker-inner-component {
  width: 100%;
  height: 2.8em;
  outline: none;
  font-size: 15px;
  text-align: left;
  border-style: none;
  padding-left: 10px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}