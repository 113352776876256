.tax-formGroup{   
    display: flex;
    flex-direction: row;
    width: 90%;
    height: fit-content;
    align-items: center;
}

.tax-input{
    width: 30%;
    height: 3em;   
    display: inline-block;    
    border: 1px solid #ccc;    
    border-radius: 4px;    
    box-sizing: border-box; 
    outline: none;
    font-size: 15px;
    text-align: left;
    padding-left: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
  
.tax-input:focus{
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}

.tax-input-long{
    width: 100%;
    height: 3em;   
    display: inline-block;    
    border: 1px solid #ccc;    
    border-radius: 4px;    
    box-sizing: border-box; 
    outline: none;
    font-size: 15px;
    text-align: left;
    padding-left: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
  
.tax-input-long:focus{
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}

.tax-button{
    width: 30%;
    height: 3em;     
    border: 1px solid #ccc;    
    border-radius: 4px;    
    box-sizing: border-box; 
    outline: none;
    font-family: 'Segoe UI';
    font-size: 15px;
    background-color: #787eff;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight:500;
    margin-left: 5px;
}

.tax-button:hover{
    background-color: #676eff;
}

.tax-button:disabled{
  background-color: #cccccc;
}

