.attendance-wrapper{
    display: flex; 
    align-items: center; 
    justify-content: center;  
    width:auto;
    height: auto;       
    padding: 15px;    
    margin: 10px;   
    min-width: 300px;
}

.attendance-form{
    display: flex; 
    flex-direction: column; 
    background-color: #f7eeee; 
    flex: 1;
    height: fit-content; 
    border-radius: 10px;
    align-items: center;
    justify-content: center;   
    padding: 5px;    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-height: 550px;
}

.attendance-header{
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;    
    flex-direction: column;
}

.attendance-body{
    width: 100%;
    flex: 7;
    display: flex;
    flex-direction: row;      
}

.attendance-footer{
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;    
    flex-direction: column;
}

.attendance-innerbox{    
    flex: 1;
    margin-right: 5px;    
}