/* COMMON DIV DESIGNED FOR VIEW SCREENS... */

.view-form {
  display: flex;
  background-color: #f7eeee;
  width: 100%;
  height: fit-content;
  min-width: 80%;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.profile-form {
  display: flex;
  background-color: #f5f2f2;
  width: 100%;
  height: fit-content;
  min-width: 80%;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.view-formGroup {
  display: flex;
  flex-direction: row;
  width: 95%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.view-footer {
  display: flex;
  background-color: #f7eeee;
  width: 100%;
  height: fit-content;
  min-width: 90%;
  border-radius: 10px;
  align-items: center;
  justify-content: right;
  flex-direction: row;
  margin-top: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.view-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 15px;
  margin: 10px;
  min-height: 600px;
  min-width: 300px;
}

/* MEDIA QUERY... */

@media screen and (max-width: 750px) {
  .view-form {
    display: flex;
    background-color: #f7eeee;
    width: 100%;
    height: fit-content;
    min-width: 80%;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
  }

  .view-footer {
    display: flex;
    background-color: #f7eeee;
    width: 100%;
    height: fit-content;
    min-width: 80%;
    border-radius: 10px;
    align-items: center;
    justify-content: right;
    flex-direction: row;
    margin-top: 10px;
    padding: 10px;
  }
}
