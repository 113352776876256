/* COMMON LABEL... */

.label {
  width: 90%;
  font-size: 15px;
  padding-bottom: 5px;
  padding-left: 5px;
  font-weight: 500;
  color: rgb(44, 53, 68);
}

.view-label {
  font-size: 15px;
  padding-bottom: 5px;
  font-weight: 500;
  color: rgb(44, 53, 68);
}

/* MESSAGES... */

.message {
  color: red;
  font-size: 12px !important;
}

/* ERROR MESSAGES... */

.error {
  width: 90%;
  color: red;
  font-size: 12px !important;
}

/* HEADS FOR FORMS... */

.form-head {
  font-weight: 500;
  font-size: 18px;
  color: rgb(100, 100, 98);
}

.form-subhead {
  font-weight: bold;
  font-size: 15px;
  color: rgb(10, 10, 10);
  width: 100%;
}
