/* COMMON DYNAMIC PAGES... */

.page {
  flex: 6;
  background-color: #e2dada;
  overflow: scroll;
  top: 50px;
  position: sticky;
}

.page::-webkit-scrollbar {
  width: 10px;
}

.page::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.page::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.page::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
