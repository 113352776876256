.topbar{
    width:100vw;
    height: 50px;
    background-color: #354052;
    position: sticky;
    top: 0;       
}

.topbar-wrapper{
    height: 100%;
    padding:0px 20px;
    display: flex;
    align-items: center;
    justify-content:space-between;
}

.topbar-left{
    flex:1; 
    display: flex; 
    justify-content: left;    
    color: rgb(226, 218, 218);
}

.topbar-logo{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff; 
}

.topbar-logoIcon{
    font-size: 30px !important;    
    color: #ffff;  
    width: 50px;
    height: 35px;
}

.topbar-logoTitle{
    margin-left: 10px; 
    font-size: 20px;
    font-weight: bold;
}

.topbar-right{
    flex:1;
    display: flex; 
    justify-content: right;    
    color: #ffff;
    font-weight: 500;
}

.topbar-formGroup{       
    display: flex;
    height: auto;
    align-items: center;
}

.topbar-label{
    width: 90%;    
    font-size: 15px;
    padding-right: 5px;    
}

.topbar-select{    
    width: fit-content;
    font-size: 15px;
    padding: 5px;   
    outline:none;
    margin-right: 10px;
    border-color:#d3c0c1;    
}

