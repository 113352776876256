.ticket-form{
    display: flex;  
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255); 
    width: 90%;
    height: 100%;
    border-style: solid;
    border-radius: 1px;
    border-color: rgb(250, 248, 248);
}

.ticket-image{
    width: 100%;
    height: calc(100% / 4);
}

.ticket-body{
    width: 100%;
    display: flex;    
    flex-direction: column;
    align-items: left;
}

.ticket-label{
    display: flex;   
    margin-bottom: 5px;
    padding: 10px;
    color: rgb(131, 114, 16);
    background-color: rgb(236, 236, 234);
}

.ticket-data{
    flex: 1;
    margin-left: 5px;
    color: black;
}

.ticket-particulars{
    flex: 1;
    margin-left: 5px;
}

.ticket-data-pending{
    flex: 1;
    margin-left: 5px;
    color: red;
}

.ticket-data-closed{
    flex: 1;
    margin-left: 5px;
    color: rgb(96, 201, 105);
}
@media print {
    .ticket-form{
        display: flex;  
        flex-direction: column;
        align-items: center;
        background-color: rgb(255, 255, 255); 
        width: 100%;
        height: 100%;
        border-style: solid;
        border-radius: 1px;
        border-color: rgb(250, 248, 248);
    }
    .page-break {
      page-break-before: always;
    }
}

