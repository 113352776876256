.dashboard-wrapper{      
    display: flex; 
    flex-direction: column;
    align-items: left; 
    justify-content: center;     
    width:fit-content;
    min-width: 97%;
    height: fit-content;       
    padding: 15px;    
    margin:10px;      
    border-radius: 10px;    
}

.dashboard-rowWrapper{      
    display: flex;    
    flex-direction: row;
    align-items: center;  
    justify-content:space-between; 
    width: auto;
    height: auto;  
    border-radius: 10px; 
    margin-top: 10px;     
}

.dashboard-card{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;    
    height: 150px;
    border-radius: 10px; 
    margin: 5px;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}

.dashboard-bigcard{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;    
    height: fit-content;
    border-radius: 10px; 
    margin: 5px;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}

.dashboard-bigcardscroll{
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;    
    height: fit-content;
    border-radius: 10px; 
    margin: 5px;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-logo{
    margin-left: auto;
    color:rgb(48, 48, 45);
    font-size: 30px !important;
    filter:drop-shadow(1px 2px 3px #666);
}

.dashboard-label{
    color:rgb(75, 52, 180);    
    font-size: 15px;
    list-style: none;
    font-weight: 500;
    margin-bottom: 10px;
}





