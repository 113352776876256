.home{
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: rgb(226, 218, 218);
    align-items: center;    
    flex-direction: column;    
}

.homeWrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - 50px);  
}




