/* COMMON INPUT... */

.input {
  width: 90%;
  height: 3em;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
}

.input:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

/* INPUT PROPERTY OF FILE INPUT... */

input[type='file'] {
  display: none;
}

/* MUI CELL SELECTION REMOVAL */

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}


/* INPUT USED IN A SEARCH BAR... */

.search-input {
  width: 90%;
  height: 3em;
  display: inline-block;
  outline: none;
  font-size: 15px;
  text-align: left;
  z-index: 1;
}

/* INPUT USED IN VIEW SCREENS... */

.view-input {
  margin-left: 10px;
  height: 3em;
  width: 50%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
}

.view-input:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

/* INPUT USED IN FILE BROWSERS... */

.browse-input {
  height: 3em;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
}

.browse-input:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

/* TEXT AREA... */

.text-area {
  height: 200px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-size: 15px;
  text-align: left;
  resize: none;
}

.text-area:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

/* SHORT INPUT... */

.short-input {
  width: 20%;
  height: 3em;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
  margin-left: 5px;
}

.short-input:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}
