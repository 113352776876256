.menu-title {
    font-size: 16px;
    color: #4d4d4d;   
    border-radius: 10px;
    padding: 8px;    
    display: flex;
    align-items: center;
    cursor: pointer;
}

.menu-title:hover{
    background-color: #787eff; 
    color: #ffff;
}

.menu-list{  
    list-style: none;
    padding: 5px;
}

.menu-link{
    text-decoration: none;
}

.menu-listItem{
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;  
    border-radius: 10px;
    color:#978303;
    margin-top: 5px;
}

.menu-listItem:hover{
    background-color: #f7a05b;  
    color: #555;
}

.menu-listItem-selected{
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;  
    border-radius: 10px;
    color: #555;
    background-color: #f7a05b;
}

.menu-listItem-selected:hover{
    background-color: #f7a05b;  
    color: #555;
}

