.row-rowWrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
}

.row-outerGroup{   
    display: flex;
    flex-direction: row;
    flex: 1;
    width:100vw;
    height: fit-content;
    align-items: center;
    justify-content: center;
}

.row-formGroup{   
    display: flex;
    flex-direction: column;
    flex: 1;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
}