/* COMMON BUTTON FOR ALL FORMS... */

.button {
  width: 50%;
  height: 3em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-family: 'Segoe UI';
  font-size: 15px;
  background-color: #787eff;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-weight: 500;
}

.button:hover {
  background-color: #676eff;
}

.button:disabled {
  background-color: #cccccc;
}

/* BUTTON FOR LANDSCAPE PAGES... */

.short-button {
  width: 40%;
  height: 3em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-family: 'Segoe UI';
  font-size: 15px;
  background-color: #787eff;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-weight: 500;
}

.short-button:hover {
  background-color: #676eff;
}

.short-button:disabled {
  background-color: #cccccc;
}

/* BUTTON FOR VIEW FORMS... */

.view-button {
  margin-left: 10px;
  height: 3em;
  width: fit-content;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-family: 'Segoe UI';
  font-size: 15px;
  background-color: #787eff;
  cursor: pointer;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 500;
}

.view-button:hover {
  background-color: #676eff;
}

.view-button:disabled {
  background-color: #cccccc;
}

.green-button {
  margin-left: 10px;
  height: 3em;
  width: fit-content;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-family: 'Segoe UI';
  font-size: 15px;
  background-color: #8ca765;
  cursor: pointer;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 500;
}

.green-button:hover {
  background-color: #758f52;
}

.green-button:disabled {
  background-color: #cccccc;
} 

/* BUTTON FOR FILE BROWSER... */

.browse-button {
  margin: 10px;
  height: 3em;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  background-color: #787eff;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.browse-button:hover {
  background-color: #676eff;
}

.browse-button:disabled {
  background-color: #cccccc;
}

/* MEDIA QUERY... */

@media screen and (max-width: 750px) {
  .button {
    width: 100%;
    height: 3em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    background-color: #787eff;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-weight: 500;
  }

  .button:hover {
    background-color: #676eff;
  }

  .button:disabled {
    background-color: #cccccc;
  }
}
