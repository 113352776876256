.webpage{
    width: 100%;
    min-height: 100vh;
    background-color: rgb(245, 240, 240);
    display: flex;
    flex-direction: column;
}

.webpage-topbar{
    width: 100%;
    height: 94px;
    position: absolute;
    z-index: 3;
    background: rgba(25, 25, 31, 0.30);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex: 1;    
    
    
    display: flex;
    flex-direction: row;  
    justify-content: space-between;    
}

.webpage-navigation {
    display: flex;
    align-items: center;
  }
.webpage-topbar-image{
    width:60%;
    height:auto;
}
.webpage-topbar-logo{
    margin-left: 5%;
    display: flex;
    justify-content: center ;
    align-items: center;
}
@media (max-width: 768px) {
    .webpage-topbar-logo{
        margin-left:-5%;
    }
}



.webpage-topbar-menu{    
    flex: 1;
    width:100vw;
    /* background-color: rgb(255, 255, 255); */
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5%;
    
}

.webiste-topbar-menuitem{
    margin: 0 2%;
    padding: 10px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

}
.webiste-topbar-menuitem, .webiste-toolbar-menuitem {
    text-decoration: none;
    color: #E3E3E3;
  }
  .webiste-topbar-menuitem:hover{
    color: #fff;
  }

  .nav-button{
    color: #fff;
    width: 6.9375em;
    height: auto;
    flex-shrink: 0;
    border-radius: 13.125em;
    background: rgba(255, 255, 255, 0.34);
    transition: ease 0.2s;
  }
  .nav-button:hover{
    color:rgba(43, 43, 43, 1);
    background: rgba(232, 232, 232, 1);
  }
  .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 2em;
    margin-right: 5%;
  }
  
  @media screen and (max-width: 768px) {
    .menu-toggle {
      display: block;
    }
  
    .webpage-topbar-menu {
      display: none;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      top: 100%;
      right: 0;
      width: 50%;
      background-color: rgba(25, 25, 31, 0.9);
      transition: height 0.3s ease-in-out;
      overflow: hidden;
      height: 0;
    }
  
    .webpage-topbar-menu.open {
      display: flex;
      height: auto;
    }
  
    .webiste-topbar-menuitem, .nav-button {
      text-align: right;
      width: 50%;
      margin-right: 25%;
    }
  }

.webpage-body{
  flex-grow: 1;
    flex: 10;   
    background-color: rgb(247, 247, 249);
}

.web-body{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.web-content{
    width: 500px;
    font-size: 22px;
    color: rgb(99, 82, 82);
    padding-right: 50px;
}

.web-image{
    height: 300px;
    width: 500px;
    border-radius: 10px;    
}

/* MEDIA QUERY... */

@media screen and (max-width: 1024px)
{
    .web-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
}

/* *******************************************Footer Section *************************************************************** */
/* Footer Styles */
.webpage-footer {
  margin: auto;
  max-width: 1440px;
  background: lightgray 0px 0px / 100% 123.796% no-repeat;;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  position: relative;
  bottom: 0;
  width: 100%;
  float: left;
}

.footer-left-section {
  display: flex;
  flex-direction: row;
}
.footer-right-section {
  display: flex;
  flex-direction: column;
}


.footer-left-section .footer-title {
  width: 169px;
  max-width: 168px;
  color: #2B353E;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 161.7%;
}

.footer-left-section .footer-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.footer-left-section li {
  margin-bottom: 12px;
}

.footer-left-section a {
  text-decoration: none;
  color: rgba(43, 53, 62, 0.70);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 161.7%;
  width: 168px;
}

.footer-left-section a:hover {
  color: #1da1f2; /* Twitter Blue */
}

.footer-right-section .footer-logo {
  background: url('../Images/LOGO_1.png');
  background-size: contain;
  width: 78px;
  height: 55px;
  flex-shrink: 0;
}
.footer-company-name {
  margin-top: 5px;
}

.footer-right-section .footer-company-name {
  color: #2B353E;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 116.016%;
  letter-spacing: -0.26px;
}

.footer-right-section .footer-description {
  color: rgba(43, 53, 62, 0.70);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 161.7%;
}
.footer-description{
  max-width: 305px;
  margin: 10px 0 62px 0;
}

.footer-right-section .footer-copyright {
  color: rgba(43, 53, 62, 0.70);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 161.7%;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .webpage-footer {
      flex-direction: column;
      align-items: flex-start;
      
  }
  
  .footer-left-section {
      order: 1;
      margin-bottom: 20px;
  }
  
  .footer-right-section {
      order: 2;
  }
}


