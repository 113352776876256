.sidebar{
    flex: 1.1;     
    background-color: #e2dada;
    overflow: scroll;  
    position: sticky;    
    height: auto;  
}

.sidebar::-webkit-scrollbar{
    width: 10px;
}
  
.sidebar::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px; 
}
   
.sidebar::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.sidebar::-webkit-scrollbar-corner{
    background: rgba(0,0,0,0);
}
 
.sidebar-wrapper{
    width: auto;
    height:auto;
    padding: 20px;
    color: #555;
}

.sidebar-menu{ 
    height:100%;    
    width: fit-content;   
    margin-bottom: 10px;    
}

@media screen and (max-width: 750px) {
    .sidebar{  
        background-color: #e2dada;
        overflow: scroll;  
        position: absolute;    
        height: calc(100vh - 100px);  
        opacity: 0.95;
        z-index: 1;
    }
}


