.item-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.item-image {
    width: 100%;
    height: 100%;
}

.item-viewGroup {
    width: 80%;
    height: 200px;
}

/* MEDIA QUERY... */

@media screen and (max-width: 1300px) {
    .item-viewGroup {
        width: 80%;
        height: 200px;
    }
}