a{
  text-decoration: none;
}.careerPage {
    font-family: Poppins, sans-serif;
  }
  
  .heroSection {
    position: relative;
    background-image: url('../Images/careerPage/CareerHeroImage.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
  
  .heroContent {
    position: absolute;
    top: 94px; /* Starts below the navbar, similar to your About page */
    left: 5%;
    /* transform: translate(-50%, 0); */
    text-align: left;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 1; /* To make sure it appears above the background but below navbar */
  }
  
  .titleContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }
  
  .titleFirstSentence,
  .titleSecondSentence {
    font-size: 75px;
    line-height: 113.4%;
    letter-spacing: -1.5px;
    display: block;
  }
  
  .titleFirstSentence {
    font-weight: 300;
  }
  
  .titleSeparator {
    width: 70px;
    height: 2px;
    background: #fff;
    margin-right: 10px;
    margin-top: -10px; /* Adjusted as you asked */
  }
  
  .titleSecondSentence {
    font-weight: 600;
  }
  
  .heroParagraph {
    font-size: 20px;
    font-weight: 500;
    line-height: 208.7%;
    color: rgba(255, 255, 255, 0.70);
    margin-top: 20px;
    max-width: 31.25em;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Responsiveness */
  @media (max-width: 1024px) {
    
    .titleFirstSentence,
    .titleSecondSentence {
      font-size: 60px;
    }
    .titleSecondSentence {
      font-weight: 600;
    }
    .heroParagraph {
      font-size: 21px;
    }
    .titleSeparator {
      width: 60px;
      
    }
  }
  @media (max-width: 843px) {
    .heroContent {
      left: 0;
      padding: 0 10px;
    }
    .titleSeparator {
      width: 60px;
      
    }
    .titleSecondSentence {
      font-weight: 600;
    }
  }
  @media (max-width: 768px) {
    .titleFirstSentence,
    .titleSecondSentence {
      font-size: 4.25rem;
    }
    .heroParagraph {
      font-size: 21px;
      line-height: 160.7%;
    }
    .titleSeparator {
      width: 45px;
      margin-top: -9px;
      /* margin-left: 5px; */
    }
    .titleSecondSentence {
      font-weight: 600;
    }
  }

  @media (max-width: 551px) {
    .titleSeparator {
      width: 80px;
      margin-top: -86px;
      /* margin-left: 5px; */
    }
    .titleSecondSentence {
      font-weight: 600;
    }
  }
  @media (max-width: 425px) {
    .titleSeparator {
      width: 50px;
      margin-top: -86px;
      /* margin-left: 5px; */
    }.titleSecondSentence {
      font-weight: 600;
    }
  }
  
  /* @media (max-width: 480px) {
    .titleFirstSentence,
    .titleSecondSentence {
      font-size: 32px;
    }
    .heroParagraph {
      font-size: 14px;
    }
    .titleSeparator {
      width: 30px;
    }
  } */

  .section2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../Images/careerPage/CareerHeroImage1.jpg');
    background-size: cover;
    background-position: center;
    filter: brightness(0.6); /* Adjust brightness as needed */
    margin-top: -14px;
  }
/*.section2::after {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%; /* Maintain a square aspect ratio 
    background-image: url('./img/CareerHeroImage2.jpg');
    background-size: cover;
    background-position: center;
    filter: brightness(0.8); /* Adjust brightness as needed 
  }*/
.section2part1{
    position: absolute;
}
.section2part2{
    
    padding-top: 7.9375em;
    color: #fff;
    width: 100%;
    overflow: hidden;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.212)), url("../Images/careerPage/CareerHeroImage1.jpg");
  }

.heroImage2{
        width: 100%;
        background-position: 0 0, 0 0;
        background-repeat: repeat, no-repeat;
        background-size: auto, cover;        
        z-index: -1;
        height: 800px;
        color: #fff;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.212)), url("../Images/careerPage/CareerHeroImage2.jpg");
        background-size: contain;
        margin-top: -2.4rem;
}
.heroImage3{
    width: 100%;
    background-position: 0 0, 0 0;
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    
    z-index: -1;
    height: 800px;
    color: #fff;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.212)), url("../Images/careerPage/CareerHeroImage2.jpg");
    background-size: cover;
    margin-top: -2.4rem;
}


  .jobBox {
    width: 100%;
    max-width: 1248px;
    height: 219px;
    display: flex;
    border: 1px solid #FFF;
    background: rgba(217, 217, 217, 0.00);
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .jobTitle {
    text-align: left;
    color: #FFF;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 113.4%; /* 45.36px */
    letter-spacing: -0.8px;
    margin: 0;
  }

  .jobDescription {
    max-width: 70%;
    color: rgba(255, 255, 255, 0.70);
    font-family: Roboto;
    text-align: left;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 113.4%; /* 26.082px */
    letter-spacing: -0.46px;
    margin: 10px 0;
  }

  .applyText {
    color: #ffffff;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 113.4%;
    letter-spacing: -0.8px;
    margin: 0;
    flex-grow: 1;
    text-align: right;
    display:inline-flex;
    text-decoration: none;
  }
  .applyText:hover {
    color: #dddd;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    .jobBox {
      flex-direction: column;
      height: auto;
    }
    .applyText {
      text-align: center;
      margin-top: 20px;
    }
   
    .jobDescription {
     
      max-width: 90%;
    }
    

  }
  .arrow {
    display: inline-block;
    width: 25.612px;
    height: 0;
    /* transform: rotate(-38.66deg); */
    stroke-width: 3px;
    stroke: #FFF;
    margin: 0 13px 0 13px; /* Adjust as needed */
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px 10px;
    background: transparent;
  }
  
   .paragraph {
    max-width: 1248px;
    width: 100%;
  }
  
  .mainParagraph {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 113.4%;
    letter-spacing: 1.35px;
  }
  
  .highlightedText {
    color: #F79F58;
    font-family: Poppins;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 113.4%;
    letter-spacing: -2.7px;
  }
  
  .secondaryParagraph {
    width: 100%;
    color: #07A18F;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 167.4%;
    letter-spacing: -2.2px;
  }
  .secondaryParagraph p{
    font-size: 1.9rem;
  }
  
  .emailLink {
    color: #07A18F;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 167.4%;
    letter-spacing: -2.2px;
    
    cursor: pointer;
  }
  .emailLink:hover {
    text-decoration: underline;
  }
  
  .secondaryEmail {
    width: 100%;
    color: #07A18F;
    text-align: center;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 167.4%;
    letter-spacing: -2.2px;
  }
  
  .developerText {
    color: #07A18F;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 167.4%;
    letter-spacing: -2.2px;
  }
  
  .thirdParagraph {
    width: 100%;
    color: rgba(255, 255, 255, 0.90);
    text-align: center;
    font-family: Poppins;
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
    line-height: 167.4%;
    letter-spacing: 4.14px;
    margin-top: 3rem;
  }
  
  .fourthParagraph {
    width: 1012px;
    max-width: 70%;
    margin: 0 auto;
    color: #858585;
    text-align: center;
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: 107.4%;
    letter-spacing: 2.07px;
  } 
  @media (max-width: 500px) {
    
    .mainParagraph {
      font-size: 2rem;
    }
    .highlightedText {
      font-size: 2rem;
    }
    .emailLink {
      font-size: 1.8rem;
      line-height: 120%;
    }
    .secondaryParagraph {
      font-size: 1rem;
    }
    .secondaryEmail {
     font-size: 1rem;
    } 
    .developerText {
      font-size: 1rem;
     } 
     .thirdParagraph {
      font-size: 1.711rem;
      letter-spacing: 3px;
      line-height: 121%;
     } 
     .fourthParagraph {
      margin-top: 0.3rem;
      font-size: 1rem;
      letter-spacing: 1px;
      line-height: 105%;
      width: 100%;
      max-width: 90%;
     }
  }
  @media (max-width: 768px) {
    
    .mainParagraph {
      font-size: 2rem;
    }
    .highlightedText {
      font-size: 2rem;
    }
    .emailLink {
      font-size: 1.8rem;
      line-height: 120%;
    }
    .secondaryParagraph {
      font-size: 2.15rem;
    }
    .secondaryEmail {
     font-size: 2.15rem;
    } 
    .developerText {
      font-size: 1.9rem;
     } 
     .thirdParagraph {
      font-size: 1.711rem;
      letter-spacing: 3px;
      line-height: 121%;
     } 
     .fourthParagraph {
      margin-top: 0.3rem;
      font-size: 1rem;
      letter-spacing: 1px;
      line-height: 105%;
      width: 100%;
      max-width: 90%;
     }
  }
  