/* COMMON DATAFRID FOR VIEW SCREENS... */

.table-container {
  display: flex;
  background-color: #f7eeee;
  width: 100%;
  height: calc(100vh - 360px);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 300px;
}

.short-table-container {
  display: flex;
  background-color: #f7eeee;
  width: 100%;
  height: calc(100vh - 560px);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 300px;
}

.long-table-container {
  display: flex;
  background-color: #f7eeee;
  width: 100%;
  height: calc(100vh - 280px);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 300px;
}

.datagrid {
  width: 100%;
  height: 100%;
  background-color: #fff; 
  border: 1px solid rgba(224, 224, 224, 1);
  overflow: scroll;
}

.tooltip-cell {
  cursor: help;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

/* .rdg-cell {    
    background-color: ffff;
    color: green;
}

.rdg-row {
  background-color: ffff;
  color: red; 
}  */

/* MEDIA QUERY... */

@media screen and (max-width: 750px) {
  .table-container {
    display: flex;
    background-color: rgb(226, 218, 218);
    width: 100%;
    height: 400px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}
