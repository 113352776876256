  /* Hero section */
  .heroSection {
    position: relative;
    height: 100vh;
  }
  
  /* Hero Image */
  .heroImage {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../Images/aboutHero.jpg");
    background-size: cover;
  }

  
  /* Hero Text */
  .heroText {
    position: absolute;
    left: 0;
    top: 94px; /* Starts below the navbar */
    margin: 2.5em 0 0 8.6875em; /* Adjusted to margin */
    z-index: 1;
    color: #FFF;
  }
  
  .title {
    font-family: Poppins;
    font-size: 4.68rem;
    font-weight: 600;
    line-height: 113.4%;
    letter-spacing: -0.98px;
    margin-bottom: 0.5em;
  }
  
  .description {
    font-family: Roboto;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-bottom: 1em;
    max-width: 31.25em;
    line-height: 146.7%; /* 30.807px */
    letter-spacing: 0.42px;
  }
  
  /* Contact Button */
  .contactButton {
    color: #000;
    line-height: inherit;
    cursor: pointer;
    background-color: #fff;
    border: none;
    border-radius: 3em;
    padding: 1rem 2.875rem;
    text-decoration: none;
    display: inline-flex;
    margin-top: 1.5em;
  }
  
  .contactButton:hover {
    color: #FFF;
    background-color: #000;
    flex-shrink: 0;
    border-radius: 60px;
    border: 1px solid #FFF;
    background: rgba(255, 0, 0, 0.00);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .title {
      font-size: 4.25rem;
      color: #FFF;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      line-height: 72px; /* 105.882% */
      letter-spacing: -0.68px;
    }
    .description {
      color: #FFF;
      font-family: Roboto;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 146.7%; /* 30.807px */
      letter-spacing: 0.42px;
    }
    .contactButton {
      padding: 0.8rem 2rem;
    }
    .heroText {
      margin: 30px 0 0 60px; /* Adjusted margin */
    }
    .heroText {
      margin: 2.5em 0 0 10px; /* Adjusted to margin */
    }
  }

  .about {
    padding-top: 7.9375em;
    color: #fff;
    width: 100vw;
    overflow: hidden;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.212)), url("../Images/aboutSection/aboutSecondImage.jpg");
    background-size: cover;
  }
  
  .aboutText, .aboutText2 {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
  
  .aboutText h1, .aboutText2 h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 75px;
    font-weight: 600;
  }
  
  .aboutSection, .aboutSection2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3.5rem 2rem;
  }
  
  .aboutBox, .aboutBox2 {
    width: 180px;
    height: 270px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px;
  }

  .aboutBox h2 {
    font-size: 19px;
    margin: 10px 0;
    min-height: 50px;  /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 85px;
    height: 85px;
    background-size: cover;
    background-position: center;
  }
  
  /* Make icons take fixed vertical space */
.icon1, .icon2, .icon3, .icon4, .icon5, .icon6 {
  /* ...existing styles... */
  min-height: 85px;  /* Make this the size of your largest icon */
  display: flex;
  align-items: center;
  justify-content: center;
}

  .icon1 { background-image: url('../Images/aboutSection/icons/1.png'); }
  .icon2 { background-image: url('../Images/aboutSection/icons/2.png'); }
  .icon3 { background-image: url('../Images/aboutSection/icons/3.png'); }
  .icon4 { background-image: url('../Images/aboutSection/icons/4.png'); }
  .icon5 { background-image: url('../Images/aboutSection/icons/5.png'); }
  .icon6 { background-image: url('../Images/aboutSection/icons/6.png'); }
  
  /* .dp1 { background-image: url(''); } 
  .dp2 { background-image: url(''); } */
  /* .dp4 { background-image: url(''); } */
  
  .dp {
    width: 115px;
    height: 115px;
    background-size: cover;
    background-position: center;
    background-color: #aaa;
    border-radius: 50%;
    margin: 0 auto 20px;
  }
  
  
  h2 {
    font-size: 19px;
    margin: 10px 0;
  }
  
  h3 {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 21px;
  }
  
  p {
    font-size: 13px;
    line-height: 150%; /* 22.581px */
  }
  
  @media (max-width: 768px) {
    .aboutBox, .aboutBox2 {
      width: calc(50% - 20px);
      padding: 2px;
    }
    p{
      line-height: 125%; /* 19.981px */
    }
    .aboutBox2 {
      
    }
  }
  