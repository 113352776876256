.staff-wrapper{
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;  
    width:auto;
    height: auto;       
    padding: 15px;    
    margin:10px; 
    min-width: 300px;
}

.staff-form{  
    display: flex;  
    background-color: #f7eeee; 
    width: 80%;
    height: fit-content;
    border-radius: 10px;
    align-items: center;
    justify-content: center;    
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.staff-controls{  
    display: flex;  
    background-color: rgb(226, 218, 218); 
    width: 80%;
    height: fit-content;
    border-radius: 10px;
    align-items: center;
    justify-content: center;    
    flex-direction: column;
    padding: 10px;
}

.staff-sectionWrapper{
    width: 100%;
    height: fit-content;
    border: solid;
    border-width: 1px;
    border-color: rgb(226, 211, 211);     
    margin: 10px;
}

.staff-rowWrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
}

.staff-formGroup{  
 
    display: flex;
    flex-direction: column;
    width: 90%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.staff-viewGroup{   
    flex: 1;
    height:fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.staff-actionGroup{   
    flex: 2;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.staff-docGroup{   
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;  
}

.staff-progress{       
    width: 100%;
    height: 1em;
    margin-top: 5px;
    margin-bottom: 5px;   
}

.staff-progressText{
    color: rgb(14, 125, 236);
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}


/* MEDIA QUERY... */

@media screen and (max-width: 750px)
{
    .staff-form{  
        display: flex;  
        background-color: #f7eeee; 
        width: 100%;
        height: fit-content;
        border-radius: 10px;
        align-items: center;
        justify-content: center;    
        flex-direction: column;
        padding: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    } 
}

