.location-wrapper{
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;  
    width:auto;
    height: auto;       
    padding: 15px;    
    margin: 10px;
    min-width: 300px;
}

.common-wrapper{
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;  
    width:auto;
    height: auto;   
    margin: 5px;    
    min-width: 300px;
}

.location-form{  
    display: flex;  
    background-color: #f7eeee; 
    width: 50%;
    height: fit-content;
    border-radius: 10px;
    align-items: center;
    justify-content: center;    
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} 

.location-formGroup{   
    display: flex;
    flex-direction: column;
    width: 90%;
    height: fit-content;
    align-items: center;
    padding: 10px;  
}

/* MEDIA QUERY... */

@media screen and (max-width: 750px)
{
    .location-form{  
        display: flex;  
        background-color: #f7eeee; 
        width: 100%;
        height: fit-content;
        border-radius: 10px;
        align-items: center;
        justify-content: center;    
        flex-direction: column;
        padding: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    } 
}