@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes fade {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fly-along {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(50px, -50px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
  
  
  .balloon-container {
    display: flex;
    align-items: center;    
  }
  
  .balloon,
  .floating-text {
    animation: float 2s infinite;
  }
  
  .birthday-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .animated-name {
    color: rgb(189, 46, 153); 
    margin-left: 10px; 
    animation: fade 2s infinite; 
    font-weight: 500;
    font-size:medium;
  }
  