/* COMMON PROGRESS BAR... */

.progress-container {
  flex: 1;
}

.progress {
  width: 100%;
  height: 1em;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-progress {
  width: 90%;
  height: 1em;
  margin-top: 5px;
  margin-bottom: 5px;
}

.progressText {
  color: rgb(14, 125, 236);
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}
