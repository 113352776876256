.login{
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #e2dada;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;    
}

.login-logo{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #626477; 
}

.login-logoIcon{
    font-size: 30px !important;    
    color: #ffff;  
    width: 300px;
    height: 200px;  
}

.login-logoTitle{
    margin-left: 10px; 
    font-size: 25px;
    font-style: italic;
    font-weight: bold;
    text-shadow: 1px 2px 3px #666;
}

.login-footer{   
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 15px;
    font-style:normal;
    font-weight:normal;
}

.login-form{  
    display: flex;  
    background-color: #f7eeee; 
    width: 400px;
    height: fit-content;  
    border-radius: 10px;
    align-items: center;
    justify-content: center;    
    flex-direction: column;
    padding: 20px;    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}    

.login-formGroup{   
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    align-items: center;
    padding: 10px;
}

/* MEDIA QUERY... */

@media screen and (max-width: 480px) {
    .login-form{  
        display: flex;  
        background-color: #f7eeee; 
        width: 80%;
        height: fit-content;  
        border-radius: 10px;
        align-items: center;
        justify-content: center;    
        flex-direction: column;
        padding: 20px;    
    } 
    

    .login-footer{   
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: #666;; 
        font-size: 15px;
        font-style:normal;
        font-weight:normal;
    }
}