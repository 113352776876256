.daily-wrapper{
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;  
    width: auto;
    height: auto;       
    padding: 15px;    
    margin: 10px;   
}

.daily-form{
    display: flex;  
    background-color: #f7eeee; 
    width: 80%;
    height: fit-content;
    min-width: 80%;      
    border-radius: 10px;
    align-items: center;
    justify-content: center;    
    flex-direction: column; 
    margin-bottom: 10px;   
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.daily-formGroup{   
    display: flex;
    flex-direction: row;
    width: 95%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.daily-datepicker{
    display: block;    
    width: 30%;
    height: 3em;  
}

.daily-datepicker:focus{
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed; 
}

.daily-datepicker-component{
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: flex-start;  
    border: 1px solid #ccc;    
    border-radius: 4px;    
    box-sizing: border-box; 
    outline: none;
    font-size: 15px;
    text-align: left; 
    padding-left: 10px;    
    background-color: white;
}

.daily-button{
    margin-left: 10px;
    height: 3em;      
    width: fit-content; 
    border: 1px solid #ccc;    
    border-radius: 4px;    
    box-sizing: border-box; 
    outline: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    background-color: #787eff;
    cursor: pointer;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.daily-button:hover{
    background-color: #676eff;
}

.daily-button:disabled{
  background-color: #cccccc;
}

.daily-label{       
    font-size: 15px;
    padding-bottom: 5px;
    font-weight:500;
    color: rgb(44, 53, 68);
    margin-left: 10px;
    margin-right: 10px;
}

.daily-container{
    display: flex; 
    flex-direction: column;     
    background-color: #f7eeee; 
    width: 80%;    
    height: calc(100vh - 300px);      
    border-radius: 10px;
    align-items: center;        
    padding: 10px;   
    overflow: auto;    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);        
}

.daily-container-inner{
    display: flex; 
    flex-direction: column;
    width: 90%;    
    height: fit-content;      
    align-items: center;                   
}

.daily-row-container{       
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;            
}

.daily-container-wrapper{
    display: flex; 
    flex-direction: row; 
    background-color: rgb(255, 255, 255); 
    width: 90%;    
    height: 100px;      
    align-items: center;
    justify-content: center;   
    padding: 10px;  
    margin: 5px;    
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.daily-row-wrapper{
    flex:3;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 10px;
}

.daily-image-wrapper{
    display: flex;
    flex: 1;
    height: 100%; 
    justify-content: center;  
}

.daily-image-container{
    aspect-ratio: 2/2;    
}

.daily-image{  
    width: 100%;
    height:100%;  
    border-radius: 50%;     
}

.daily-column-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
}

.daily-column-wrapper1{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    font-weight: bold;
    text-transform: uppercase;
}

.daily-column-wrapper2{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    font-size: small;    
}

.daily-column-group{
    width: 100%;
    flex: 1;
}

.attendance-select{
    width: 25%;
    height: 3em;
    font-size: 15px;    
    padding: 5px;   
    outline:none;
    border: 1px solid #ccc; 
    border-radius: 4px;  
    background-color: white;
    margin-left: 10px;
}

.attendance-select:focus{
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed; 
}

.attendance-input{
    margin-left: 10px;
    height: 3em;   
    width: 25%;
    border: 1px solid #ccc;    
    border-radius: 4px;    
    box-sizing: border-box; 
    outline: none;
    font-size: 15px;
    text-align: left;
    padding-left: 10px;
}

.attendance-input:focus{
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}

.attendance-label{  
    margin-left: 10px;     
    font-size: 15px;
    padding-bottom: 5px;
    font-weight:500;
    color: rgb(44, 53, 68);
}

/* MEDIA QUERY... */

@media screen and (max-width: 750px)
{
    .daily-form{
        display: flex;  
        background-color: #f7eeee; 
        width: 100%;
        height: fit-content;
        min-width: 80%;      
        border-radius: 10px;
        align-items: center;
        justify-content: center;    
        flex-direction: column; 
        margin-bottom: 10px;   
        padding: 10px;
    }

    .daily-container{
        background-color: #f7eeee; 
        width: 100%;    
        height: 400px;      
        border-radius: 10px;
        align-items: center;      
        padding: 10px;  
        overflow-x: auto;
    }

    .daily-container-wrapper{
        display: flex; 
        flex-direction: row; 
        background-color: rgb(255, 255, 255); 
        width: 95%;    
        height: 80px;      
        align-items: center;
        justify-content: center;   
        padding: 10px;  
        margin: 5px;
    }    
}

@media print
{
    @page {
        margin: 1.5cm;
    }

}




