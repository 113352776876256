/* COMMON SELECT... */

.select {
  width: 90%;
  height: 3em;
  font-size: 15px;
  padding: 5px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.select:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

/* SELECT FOR VIEW SCREEN... */

.view-select {
  width: 12em;
  height: 3em;
  font-size: 15px;
  padding: 5px;
  outline: none;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.view-select:focus {
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

/* SELECT USED IN EXPENSE PAGE... */

.expense-select {
  width: 12em;
  height: 3em;
  font-size: 15px;
  padding: 5px;
  outline: none;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.expense-select:focus {
  border-color: #9ecaed;
}

/* SELECT USED IN SHOW LEDGER PAGE... */

.ledger-select {
  width: 40%;
  height: 3em;
  font-size: 15px;
  padding: 5px;
  outline: none;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.ledger-select:focus {
  border-color: #9ecaed;
}
